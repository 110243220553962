import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconDelete.module.css';

const IconDelete = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#E4696A"
        d="M7 21c-.55 0-1.021-.196-1.413-.588A1.922 1.922 0 015 19V6H4V4h5V3h6v1h5v2h-1v13c0 .55-.196 1.021-.588 1.413A1.922 1.922 0 0117 21H7zM17 6H7v13h10V6zM9 17h2V8H9v9zm4 0h2V8h-2v9z"
      ></path>
    </svg>
  );
};

IconDelete.defaultProps = { className: null };

IconDelete.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconDelete;
