import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SocialSharePopup.module.css';

const SocialShareIcons = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.rootIcon, className);

  if (type === "copy") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={classes}
        ariaHidden="true"
        display="block"
        viewBox="0 0 32 32"
        fill="#222"
      >
        <path d="M25 5a4 4 0 014 4v17a5 5 0 01-5 5H12a5 5 0 01-5-5V10a5 5 0 015-5h13zm0 2H12a3 3 0 00-3 3v16a3 3 0 003 3h12a3 3 0 003-3V9a2 2 0 00-2-2zm-3-6v2H11a6 6 0 00-6 5.78V22H3V9a8 8 0 017.75-8H22z" fill="#222"></path>
      </svg>
    )
  } else if (type === "share") {
    return (
      <svg className={css.share} width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 13V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 13M16 8L12 4M12 4L8 8M12 4L12 16"  fill="#ffffff" stroke="#33a4d6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  } else if (type === "email") {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="32px"
        height="32px"
        viewBox="0 0 122 88"
        enableBackground="new 0 0 122 88"
      >
        <g>
          <path d="M7.048,0h108.784c1.939,0,3.701,0.794,4.977,2.069c1.277,1.277,2.07,3.042,2.07,4.979v74.759 c0,1.461-0.451,2.822-1.221,3.951c-0.141,0.365-0.361,0.705-0.662,0.994c-0.201,0.189-0.422,0.344-0.656,0.461 c-1.225,1.021-2.799,1.643-4.508,1.643H7.048c-1.937,0-3.701-0.793-4.979-2.07C0.794,85.51,0,83.748,0,81.807V7.048 c0-1.941,0.792-3.704,2.068-4.979C3.344,0.792,5.107,0,7.048,0L7.048,0z M5.406,78.842l38.124-38.22L5.406,9.538V78.842 L5.406,78.842z M47.729,44.045L8.424,83.449h105.701L76.563,44.051L64.18,54.602l0,0c-0.971,0.83-2.425,0.877-3.453,0.043 L47.729,44.045L47.729,44.045z M80.674,40.549l36.799,38.598V9.198L80.674,40.549L80.674,40.549z M8.867,5.406l53.521,43.639 l51.223-43.639H8.867L8.867,5.406z"/>
        </g>
      </svg>
    )
  } else if (type === "whatsApp") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={classes}
        ariaHidden="true"
        display="block"
        viewBox="0 0 32 32"
      >
        <path fill="#25d366" d="M32 0v32H0V0z"></path>
        <path
          fill="#FFF"
          d="M4 28l1.7-6.16a11.82 11.82 0 01-1.6-5.95C4.1 9.33 9.46 4 16.05 4a11.9 11.9 0 018.45 3.49A11.8 11.8 0 0128 15.9a11.94 11.94 0 01-17.66 10.45zm6.63-3.8a9.93 9.93 0 0015.35-8.3A9.9 9.9 0 0016.05 6a9.92 9.92 0 00-9.93 9.9c0 2.22.65 3.88 1.75 5.63l-1 3.64 3.76-.98zm11.36-5.52c-.07-.13-.27-.2-.57-.35-.3-.15-1.75-.86-2.03-.96-.27-.1-.46-.15-.66.15s-.77.96-.94 1.15-.35.23-.65.08c-.3-.15-1.25-.46-2.38-1.47-.88-.78-1.48-1.75-1.65-2.04s-.02-.46.13-.6l.44-.52c.15-.17.2-.3.3-.5.1-.2.05-.36-.02-.51-.08-.15-.67-1.6-.92-2.2-.24-.57-.48-.5-.66-.5l-.57-.01a1.09 1.09 0 00-.8.37c-.27.3-1.03 1.01-1.03 2.46s1.06 2.86 1.2 3.06c.16.2 2.1 3.18 5.08 4.45.7.3 1.26.5 1.69.63.7.22 1.36.19 1.87.11.57-.08 1.75-.71 2-1.4s.25-1.28.17-1.4z"
        ></path>
      </svg>
    )
  } else if (type === "messenger") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={classes}
        ariaHidden="true"
        display="block"
        viewBox="0 0 32 32"
      >
        <radialGradient id="a" cx="19.25%" cy="99.45%" r="108.96%">
          <stop offset="0" stopColor="#09f"></stop>
          <stop offset="0.61" stopColor="#a033ff"></stop>
          <stop offset="0.93" stopColor="#ff5280"></stop>
          <stop offset="1" stopColor="#ff7061"></stop>
        </radialGradient>
        <g fill="none">
          <path
            fill="url(#a)"
            d="M12 0C5.24 0 0 4.95 0 11.64c0 3.5 1.43 6.52 3.77 8.6.2.18.31.43.32.7l.06 2.13a.96.96 0 001.35.85l2.38-1.05c.2-.1.43-.1.65-.05 1.1.3 2.25.46 3.47.46 6.76 0 12-4.95 12-11.64C24 4.96 18.76 0 12 0z"
            transform="translate(4 4)"
          ></path>
          <path
            fill="#fff"
            d="M8.8 19.04l3.52-5.59a1.8 1.8 0 012.6-.48l2.8 2.1c.27.2.62.2.88 0l3.78-2.87c.5-.39 1.17.22.83.76l-3.53 5.59a1.8 1.8 0 01-2.6.48l-2.8-2.1a.72.72 0 00-.87 0L9.6 19.8c-.5.38-1.15-.22-.82-.76z"
          ></path>
        </g>
      </svg>
    )
  } else if (type === "facebook") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={classes}
        ariaHidden="true"
        display="block"
        viewBox="0 0 32 32"
      >
        <path fill="#1877F2" d="M32 0v32H0V0z"></path>
        <path
          fill="#FFF"
          d="M22.94 16H18.5v-3c0-1.27.62-2.5 2.6-2.5h2.02V6.56s-1.83-.31-3.58-.31c-3.65 0-6.04 2.21-6.04 6.22V16H9.44v4.63h4.06V32h5V20.62h3.73l.7-4.62z"
        ></path>
      </svg>
    )
  } else if (type === "twitter") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={classes}
        ariaHidden="true"
        display="block"
        viewBox="0 0 32 32"
      >
        <path fill="#1DA1F2" d="M0 0h32v32H0z"></path>
        <path
          fill="#FFF"
          d="M18.66 7.99a4.5 4.5 0 00-2.28 4.88A12.31 12.31 0 017.3 8.25a4.25 4.25 0 001.38 5.88c-.69 0-1.38-.13-2-.44a4.54 4.54 0 003.5 4.31 4.3 4.3 0 01-2 .06 4.64 4.64 0 004.18 3.13A8.33 8.33 0 015.82 23a12.44 12.44 0 0019.31-11.19 7.72 7.72 0 002.2-2.31 8.3 8.3 0 01-2.5.75 4.7 4.7 0 002-2.5c-.88.5-1.82.88-2.82 1.06A4.5 4.5 0 0018.66 8z"
        ></path>
      </svg>
    )
  } else if (type === "messages") {
    return (
      <svg
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        ariaHidden="true"
        display="block"
        width="32"
        height="32"
      >
        <g strokeWidth="0"></g>
        <g strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <g transform="translate(5.796 8.927)">
            <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="-1183.897" y1="1855.346" x2="-1119.897" y2="1855.346" gradientTransform="matrix(0 1 1 0 -1829.142 1174.97)">
              <stop offset="0" stopColor="#00ea66"></stop>
              <stop offset="1" stopColor="#00d50f"></stop>
            </linearGradient>
            <path d="M58.204 6.073v34c0 8.284-6.716 15-15 15h-34c-8.284 0-15-6.716-15-15v-34c0-8.284 6.716-15 15-15h34c8.284 0 15 6.716 15 15z" fill="url(#a)"></path>
            <g transform="translate(-10.184 -993.295)">
              <linearGradient id="b" gradientUnits="userSpaceOnUse" x1="-904.919" y1="1071.927" x2="-904.919" y2="1092.493" gradientTransform="matrix(.794 0 0 -.8101 754.868 1899.425)">
                <stop offset="0" stopColor="#e5f5d9"></stop>
                <stop offset="1" stopColor="#ffffff"></stop>
              </linearGradient>
              <path d="M36.4 1001.368c-3.512 0-6.761.908-9.47 2.473-.459.265-.91.531-1.335.832-.41.292-.786.611-1.162.934-.014.011-.036.013-.05.025-3.076 2.637-4.995 6.271-4.995 10.294 0 3.965 1.874 7.541 4.872 10.167.014.107.024.223.024.303 0 2.322-1.786 4.214-4.055 4.239a9.41 9.41 0 0 0 3.61.731c2.19 0 4.163-.814 5.762-2.094a19.415 19.415 0 0 0 6.8 1.236c9.388 0 16.988-6.535 16.988-14.583s-7.601-14.557-16.989-14.557z" fill="url(#b)"></path>
            </g>
          </g>
        </g>
      </svg>)
  } else if ( type === "embed"){
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="32px"
        height="32px"
        viewBox="0 0 122.88 101.57"
        enableBackground="new 0 0 122.88 101.57"
      >
        <g>
          <path d="M44.97,12.84h-17.2L0,49.37L27.77,85.9h17.2L17.2,49.37L44.97,12.84L44.97,12.84z M77.91,12.84h17.2l27.77,36.53 L95.11,85.9h-17.2l27.77-36.53L77.91,12.84L77.91,12.84z M70.17,0.04l5.96,1.39c0.94,0.22,1.52,1.16,1.31,2.1l-22.5,96.69 c-0.22,0.93-1.16,1.52-2.1,1.31l-5.95-1.39c-0.94-0.22-1.52-1.16-1.31-2.1l22.5-96.69C68.3,0.42,69.24-0.17,70.17,0.04L70.17,0.04 L70.17,0.04z"/>
        </g>
      </svg>
    )
  }  else {
    return null
  }
};

SocialShareIcons.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SocialShareIcons.propTypes = {
  rootClassName: string,
  className: string,
};

export default SocialShareIcons;
