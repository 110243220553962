import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import {
  Button,
  PrimaryButton
} from '../../components';
import css from './RemoveFavoritesList.module.css';


const RemoveFavoritesList = props => {
  const { onClosePopup, onDeleteList, listTitle } = props;

  return (
    <div className={css.listPopupContent}>
      <h2 className={css.listPopupTitle}>
        <FormattedMessage id="FavoritesPage.removeList" />
      </h2>
      <div className={css.listPopupForm}>
        <p className={css.listPopupDescription}>
          <FormattedMessage id="FavoritesPage.removeListDescription" values={{ title: listTitle }} />
        </p>
        <div className={css.listPopupButtons}>
          <PrimaryButton
            className={css.listPopupButtonCancel}
            onClick={onClosePopup}
          >
            <FormattedMessage id="FavoritesPage.cancel" />
          </PrimaryButton>
          <Button
            className={css.listPopupButtonDelete}
            onClick={onDeleteList}
          >
            <FormattedMessage id="FavoritesPage.delete" />
          </Button>
        </div>
      </div>
    </div>
  );
};


export default RemoveFavoritesList;



