import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import {
  IconClose,
  NamedLink,
  IconLogoCustom
} from '../../components';
import css from './FavoritesListCards.module.css';

const FavoritesListCards = props => {
  const {
    currentUser,
    favorites,
    updateExistingList,
    onOpenDeleteListPopup,
    isLinkForSearch,
  } = props;


  if (isLinkForSearch) {
    return (
      <ul className={css.favoritesListGrid}>
        {favorites.map(item => {
          return (
            <li
              key={item.favoritesId}
              className={css.favoritesListLinkItem}
            >
              <div className={css.favoritesDelete} onClick={() => onOpenDeleteListPopup(item)} >
                <IconClose />
              </div>
              <NamedLink
                className={css.favoritesListLink}
                name="FavoritesSearchPage"
                to={{ search: `favorites_list_id=${item.favoritesId}&user_id=${currentUser?.id?.uuid}` }}
              >
                {!!item?.favoritesListings?.length ? (
                  <img
                    className={css.favoritesListLinkImage}
                    src={item?.favoritesImage?.[1] || item?.favoritesImage?.[0]}
                    alt={item?.favoritesTitle}
                  />
                ) : (
                  <div className={css.favoritesImageHolder}>
                    <IconLogoCustom className={css.favoritesListLogo} />
                  </div>
                )}
                <h2 className={css.favoritesListLinkTitle}>
                  {item?.favoritesTitle}
                </h2>
                <p className={css.favoritesListLinkCounter}>
                  <FormattedMessage
                    id="FavoritesPage.addedToFavorite"
                    values={{ counter: item?.favoritesListings?.length }}
                  />
                </p>
              </NamedLink>
            </li>
          )
        })}
      </ul>
    )
  } else {
    return (
      <ul className={css.favoritesList}>
        {favorites.map(item => {
          return (
            <li
              key={item.favoritesId}
              className={css.favoritesListItem}
              onClick={() => updateExistingList(item.favoritesId)}
            >
              {!!item?.favoritesListings?.length ? (
                <img
                  className={css.favoritesListImage}
                  src={item?.favoritesImage?.[1] || item?.favoritesImage?.[0]}
                  alt={item?.favoritesTitle}
                />
              ) : (
                <div className={css.favoritesImageHolder}>
                  <IconLogoCustom className={css.favoritesListLogo} />
                </div>
              )}
              <h3 className={css.favoritesListTitle}>
                {item?.favoritesTitle}
              </h3>

              <p className={css.favoritesListCounter}>
                <FormattedMessage
                  id="FavoritesPage.addedToFavorite"
                  values={{ counter: item?.favoritesListings?.length }}
                />
              </p>
            </li>
          )
        })}
      </ul>
    );
  }
};


export default FavoritesListCards;



