import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';

import { types as sdkTypes } from '../../util/sdkLoader';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemProviderCancel = props => {
  const { transaction, isProvider, intl, marketplaceCurrency } = props;

  const depositFee = transaction.attributes.lineItems.find(
    ({code}) => code === "line-item/security-deposit-fee"
  );

  const depositFeeAmount = depositFee ? depositFee.lineTotal.amount : 0;
  const charge = transaction.attributes.metadata?.charge?.amount ?? 0;
  const transfer = transaction.attributes.metadata?.transfer?.amount ?? 0;

  const amount = charge
    ? charge + Math.abs(depositFeeAmount)
    : transfer
    ? Math.abs(depositFeeAmount) - transfer
    : 0;

  return amount && isProvider ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.providerCharge" />
      </span>
      <span className={css.itemValue}>
        {formatMoney(intl, new Money(amount, marketplaceCurrency))}
      </span>
    </div>
  ) : null;
};


export default LineItemProviderCancel;
