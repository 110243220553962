import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconDates.module.css';

const IconDates = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#222"
        d="M20.625 4.313h-3.938v-1.5a.188.188 0 00-.187-.188h-1.313a.188.188 0 00-.187.188v1.5H9v-1.5a.188.188 0 00-.188-.188H7.5a.188.188 0 00-.188.188v1.5H3.376a.75.75 0 00-.75.75v15.562c0 .415.335.75.75.75h17.25a.75.75 0 00.75-.75V5.062a.75.75 0 00-.75-.75zm-.938 15.375H4.313V10.78h15.375v8.906zM4.313 9.188V6h3v1.125c0 .103.085.188.188.188h1.313A.188.188 0 009 7.125V6h6v1.125c0 .103.084.188.188.188H16.5a.188.188 0 00.188-.188V6h3v3.188H4.313z"
      ></path>
      <path fill="#222" d="M7 12H9V14H7z"></path>
      <path fill="#222" d="M7 16H9V18H7z"></path>
      <path fill="#222" d="M11 12H13V14H11z"></path>
      <path fill="#222" d="M11 16H13V18H11z"></path>
      <path fill="#222" d="M15 12H17V14H15z"></path>
      <path fill="#222" d="M15 16H17V18H15z"></path>
    </svg>
  );
};

IconDates.defaultProps = {
  rootClassName: null,
  className: null,
  fillColor: null,
};

IconDates.propTypes = {
  rootClassName: string,
  className: string,
  fillColor: string,
};

export default IconDates;
