import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';

import css from './CancellationPolicyInfo.module.css';

const CancellationPolicyInfo = props => {
  const { listing } = props;

  if (!listing) return null;

  const selectedOption = listing?.attributes?.publicData?.cancellationPolicy;
  const cancellationPolicyPercentage = listing?.attributes?.publicData?.cancellationPolicyPercentage;

  return (
    <div className={css.root}>

      <h3 className={css.policyTitle}>
        <FormattedMessage id="CancellationPolicyOptions.policyTitle" />
      </h3>
      <p className={css.policyItem}>
        <FormattedMessage
          id="CancellationPolicyOptions.policyPeriod"
          values={{ value: <b>Up to {selectedOption < 3 ? `${selectedOption * 24} hours` : `${selectedOption} days`} before</b> }}
        />
      </p>
      <p className={css.policyItem}>
        <FormattedMessage
          id="CancellationPolicyOptions.policyPercentage"
          values={{ value: <b>{cancellationPolicyPercentage}%</b> }}
        />
      </p>
    </div>
  );
};

CancellationPolicyInfo.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CancellationPolicyInfo.propTypes = {
  rootClassName: string,
  className: string,
};

export default CancellationPolicyInfo;
