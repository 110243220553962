import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSearchMapView.module.css';

const IconSearchMapView = props => {
  const { rootClassName, className, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (type === "list") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="#fff"
          d="M3 3.75v4.5h4.5v-4.5H3zm1.5 1.5H6v1.5H4.5v-1.5zm4.5 0v1.5h11.25v-1.5H9zm-6 4.5v4.5h4.5v-4.5H3zm1.5 1.5H6v1.5H4.5v-1.5zm4.5 0v1.5h11.25v-1.5H9zm-6 4.5v4.5h4.5v-4.5H3zm1.5 1.5H6v1.5H4.5v-1.5zm4.5 0v1.5h11.25v-1.5H9z"
        ></path>
      </svg>
    )
  } else {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#33A4D6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M15.375 6v15.75m-6.75-19.5V18m6.06-12.158l-5.37-3.435a1.5 1.5 0 00-1.372.017L2.649 5.976a.75.75 0 00-.399.663v13.866a.75.75 0 001.101.662l4.585-2.99a1.5 1.5 0 011.383-.011l5.244 3.422a1.5 1.5 0 001.372-.005l5.41-3.56a.75.75 0 00.405-.666V3.495a.75.75 0 00-1.101-.662l-4.594 2.993a1.5 1.5 0 01-1.37.016z"
        ></path>
      </svg>
    );
  }

};

IconSearchMapView.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSearchMapView.propTypes = { rootClassName: string, className: string };

export default IconSearchMapView;
