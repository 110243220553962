import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { IconFavorite, Modal } from '../../components';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { setFavoritesListingIdToUserProfile } from '../../ducks/user.duck';
import { createSlug } from '../../util/urlHelpers';
import AddFavoritesForm from './AddFavoritesForm';
import AddToExistingFavorites from './AddToExistingFavorites';

import css from './AddFavorites.module.css';
import {createResourceLocatorString} from "../../util/routes";
import {useRouteConfiguration} from "../../context/routeConfigurationContext";

const AddFavoritesComponent = props => {
  const {
    listing,
    currentUser,
    onUpdateProfile,
    favoritesListingsInProgress,
    className,
    onManageDisableScrolling,
    showText,
    showTextWithIcon,
    onDeleteFromFavorites,
    history,
    isAuthenticated,
    isOverlayPosition
  } = props;

  const [showNewListPopup, setShowNewListPopup] = useState(false);
  const [showExistingListPopup, setShowExistingListPopup] = useState(false);

  const onClosePopup = () => {
    setShowNewListPopup(false);
    setShowExistingListPopup(false);

    setTimeout(() => {
      typeof window !== 'undefined' && window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
    }, 200)
  }

  const listingId = listing?.id?.uuid;
  const listingImages = [
    listing?.images?.[0]?.attributes?.variants?.['listing-card']?.url,
    listing?.images?.[0]?.attributes?.variants?.['listing-card-2x']?.url
  ];

  const favorites = currentUser?.attributes?.profile?.publicData?.favorites;
  const favoritesListingsArray = !!favorites && []?.concat?.apply([], favorites?.map(item => item.favoritesListings)) || [];
  const isFavorite = favoritesListingsArray?.includes(listingId);


  const removeFromFavorites = () => {
    if (favoritesListingsInProgress) {
      return;
    }
    const data = favorites.map(item => {
      if (!!item?.favoritesListings && item.favoritesListings.includes(listingId)) {
        let updatedList = item?.favoritesListings?.filter(l => l !== listingId);
        item.favoritesListings = updatedList;
        return item;
      }
      return item
    })

    onUpdateProfile(data);
    setShowNewListPopup(false);

    setTimeout(() => {
      !!onDeleteFromFavorites && onDeleteFromFavorites();
      // typeof window !== 'undefined' && window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
    }, 200)
  };


  const createNewList = (values) => {

    if (!values?.title || favoritesListingsInProgress) return null

    const data = [{
      favoritesId: `${createSlug(values.title)}-${Math.random().toFixed(4)}-${Math.random().toFixed(4)}`,
      favoritesTitle: values.title,
      favoritesImage: listingImages,
      favoritesListings: !!listingId ? [listingId] : null
    }]

    const {favorites: oldFavorites = []} = currentUser?.attributes?.profile?.publicData || {};

    if(!isAuthenticated) {
      const favorites = { fromFavorites: true, data };
      if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.setItem('favorites', JSON.stringify(favorites))
        history.push('/login', {from: `/s` })
      }
    } else {
      onUpdateProfile([...oldFavorites, ...data]);
    }


    setShowNewListPopup(false);

    // setTimeout(() => {
    //   typeof window !== 'undefined' && window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
    // }, 200)
  }


  const updateExistingList = (listId) => {

    if(!favorites?.length) return null

    const data = favorites.map(item => {

      if (!item?.favoritesListings?.length && item?.favoritesId === listId && !item.favoritesListings.includes(listingId)) {
        item.favoritesImage = listingImages;
        item?.favoritesListings?.push(listingId);
        return item;
      }
      else if (item?.favoritesId === listId && !item.favoritesListings.includes(listingId)){
        item?.favoritesListings?.push(listingId);
        return item;
      }
      return item
    })

    if(!isAuthenticated) {
      history.push('/login', {from: `/s`, fromFavorites: true, data})
    } else {
      onUpdateProfile(data);
    }

    setShowExistingListPopup(false);

    // setTimeout(() => {
    //   typeof window !== 'undefined' && window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
    // }, 200)
  }


  return (
    <>
      <div
        className={classNames(css.root, className)}
        onClick={
            isFavorite ? removeFromFavorites : !!favorites?.length
            ? () => setShowExistingListPopup(true)
            : () => setShowNewListPopup(true)}
      >
        <IconFavorite
          isFavorite={isFavorite}
          iconClass={classNames(css.icon, { [css.iconActive]: isFavorite })}
          showText={showText}
          showTextWithIcon={showTextWithIcon}
          isOverlayPosition={isOverlayPosition}
        />
      </div>

      <Modal
        id="ExistingListPopup"
        isOpen={showExistingListPopup}
        onClose={() => setShowExistingListPopup(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.listExistingPopupRoot}
      >
        <div className={css.listPopupContent}>
          <h2 className={css.listPopupTitle}>
            <FormattedMessage id="FavoritesPage.addToFavoritesTitle" />
          </h2>
          <div className={css.listPopupForm}>

            <AddToExistingFavorites
              key={`AddToExistingFavorites-${listingId}-${Math.random().toFixed(4)}`}
              favorites={favorites}
              updateExistingList={updateExistingList}
              showNewListPopup={() => {
                setShowNewListPopup(true),
                setShowExistingListPopup(false)
              }}
              onCloseModal={onClosePopup}
            />

          </div>
        </div>
      </Modal>

      <Modal
        id="NewListPopup"
        isOpen={showNewListPopup}
        onClose={() => setShowNewListPopup(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.listPopupRoot}
      >
        <div className={css.listPopupContent}>
          <h2 className={css.listPopupTitle}>
            <FormattedMessage id="FavoritesPage.createListTitle" />
          </h2>
          <div className={css.listPopupForm}>
            <AddFavoritesForm
              key={`AddFavoritesForm-${listingId}-${Math.random().toFixed(4)}`}
              onSubmit={createNewList}
              onCloseModal={onClosePopup}
            />
          </div>
        </div>
      </Modal>
    </>
  );

};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    currentUser,
    favoritesListingsInProgress,
  } = state.user;

  return {
    currentUser,
    isAuthenticated,
    favoritesListingsInProgress
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(setFavoritesListingIdToUserProfile(data)),
});

const AddFavorites = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AddFavoritesComponent);

export default AddFavorites;
