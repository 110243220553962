import React from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import {
  Form,
  FieldLocationAutocompleteInput,
  Button,
  IconSearch,
} from '../../components';
import BookingDateRangeFilter from '../../containers/SearchPage/BookingDateRangeFilter/BookingDateRangeFilter';

import css from './AdvancedSearch.module.css';

const identity = v => v;

export const AdvancedSearchForm = props => (
  <FinalForm
    mutators={{ ...arrayMutators }}
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        onSubmit,
        getValues,
        updatesDatesFilter,
        datesFilter,
        onClearLocation,
        currentPage,
        onClearDates
      } = formRenderProps;

      // console.log(values, 'values')

      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      // const submitDisabled = invalid || disabled || submitInProgress;

      const currentLocationSelected = !!values?.location?.selectedPlace?.origin;
      const datesFilterSelected = !!datesFilter?.dates;

      return (
        <Form className={css.rootForm} onSubmit={(e) => { e.preventDefault(), onSubmit }}>

          <FieldLocationAutocompleteInput
            rootClassName={css.locationAddress}
            inputClassName={classNames(css.locationAutocompleteInput, {[css.locationAutocompleteInputActive]: currentLocationSelected && currentPage === "SearchPage"})}
            iconClassName={css.locationAutocompleteInputIcon}
            validClassName={css.validLocation}
            autoFocus={false}
            name="location"
            placeholder={intl.formatMessage({
              id: 'AdvancedSearch.locationPlaceholder',
            })}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
            showClearButton={!!onClearLocation && currentLocationSelected && currentPage === "SearchPage"}
            onClearLocation={onClearLocation}
            css={css}
          />

          <div className={classNames(css.dates, { [css.datesActive]: datesFilterSelected })}>
            <BookingDateRangeFilter
              id='dates'
              name='dates'
              label={intl.formatMessage({
                id: 'AdvancedSearch.datePlaceholder',
              })}
              queryParamNames={["dates"]}
              initialValues={datesFilter}
              onSubmit={(values) => updatesDatesFilter(values)}
              showAsPopup
              showLabelIcon
              onClearDates={onClearDates}
            />
          </div>

          {(!!currentLocationSelected || !!datesFilterSelected) && (
            <Button
              className={css.submitButton}
              type="button"
              inProgress={submitInProgress}
              ready={submitReady}
              onClick={() => getValues(values)}
            >
              <IconSearch />
            </Button>
          )}

        </Form>
      );
    }}
  />
);

export default compose(injectIntl)(AdvancedSearchForm);
