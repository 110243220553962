import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconBlocked.module.css';

const IconBlocked = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_563_3575)">
        <path
          fill="#000"
          d="M10 0C4.478 0 0 4.478 0 10s4.478 10 10 10 10-4.478 10-10S15.522 0 10 0zm0 18.304A8.304 8.304 0 013.56 4.759l11.681 11.68A8.265 8.265 0 0110 18.304zm6.44-3.063L4.759 3.561a8.305 8.305 0 0111.68 11.68h.001z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_563_3575">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

IconBlocked.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconBlocked.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconBlocked;
