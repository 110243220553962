import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.module.css';

import {
  FishingGear,
  PaddleBoards,
  Utvs,
  MountainBikes,
  Boats,
  JetSkis,
  WaterSports,
  CampingGear,
  Scooters,
  TourPackage,
  RockClimbingGear,
  RvTrailer,
  HangGliding,
  Paragliding,
  Atvs,
  WinterSports,
  GolfGear,
  Ebikes,
  TowingHaulingEquipment,
  Motorcycles,
} from '../../components/ListingTypesIcons/ListingTypesIcons';

const listingTypesIcons = {
  fishingGear: FishingGear,
  paddleBoards: PaddleBoards,
  utvs: Utvs,
  mountainBikes: MountainBikes,
  boats: Boats,
  waterSports: WaterSports,
  jetSkis: JetSkis,
  campingGear: CampingGear,
  scooters: Scooters,
  tourPackage: TourPackage,
  rockClimbingGear: RockClimbingGear,
  rvTrailer: RvTrailer,
  hangGliding: HangGliding,
  paragliding: Paragliding,
  atvs: Atvs,
  winterSports: WinterSports,
  golfGear: GolfGear,
  ebikes: Ebikes,
  towingHaulingEquipment: TowingHaulingEquipment,
  motorcycles: Motorcycles,
}

const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <rect
        className={boxClassName || css.box}
        width="17"
        height="17"
        x="0.5"
        y="0.5"
        fill="#fff"
        stroke="#DDD"
        rx="1.5"
      ></rect>
      <path
        className={checkedClassName || css.checked}
        fill="#fff"
        d="M7 7.78L4.22 5l-.947.94L7 9.667l8-8-.94-.94L7 7.78z"
        y="1"
      ></path>
    </svg>
  );
};

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    label,
    iconKey,
    useSuccessColor,
    withIcons,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className, { [css.rootWithIcons]: withIcons });
  const ItemTypeIcon = listingTypesIcons[iconKey];

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);

    // If onChange has been passed as a props to FieldCheckbox
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
      checkedClassName: css.checkedSuccess,
      boxClassName: css.boxSuccess,
    }
    : {};
  const disabledColorMaybe = rest.disabled
    ? {
      checkedClassName: css.checkedDisabled,
      boxClassName: css.boxDisabled,
    }
    : {};

  return (
    <span className={classes}>
      <Field type="checkbox" {...rest}>
        {props => {
          const { input, disabled } = props;
          return (
            <input
              id={id}
              className={css.input}
              {...input}
              onChange={event => handleOnChange(input, event)}
              disabled={disabled}
            />
          );
        }}
      </Field>
      <label htmlFor={id} className={css.label}>
        <span className={css.checkboxWrapper}>
          <IconCheckbox
            className={svgClassName}
            {...successColorVariantMaybe}
            {...disabledColorMaybe}
          />
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>
          {!!withIcons && ItemTypeIcon && <ItemTypeIcon />}
          {label}
        </span>
      </label>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxComponent;
