import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import { acceptedTransitions } from '../../constants/acceptedTransitions';
import { completedTransitions } from '../../constants/completedTransitions';
import { requestedTransitions } from '../../constants/requestedTransitions';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_LISTINGS_REQUEST = 'app/InboxPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/InboxPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/InboxPage/FETCH_LISTINGS_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  hasListing: false,
};

export default function inboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FETCH_LISTINGS_REQUEST:
      return state;
    case FETCH_LISTINGS_SUCCESS:
      return { ...state, hasListing: payload };
    case FETCH_LISTINGS_ERROR:
      return { ...state, hasListing: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});

export const queryListingsSuccess = payload => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload,
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const queryOwnListings = () => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  return sdk.ownListings
    .query()
    .then(response => {
      const hasListings = response.data.data.length > 0;
      dispatch(queryListingsSuccess(hasListings));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab, role } = params;

  // const onlyFilterValues = {
  //   orders: 'order',
  //   sales: 'sale',
  // };

  // const onlyFilter = onlyFilterValues[tab];
  // if (!onlyFilter) {
  //   return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  // }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  let lastTransitions;

  switch (tab) {
    case 'accepted':
      lastTransitions = acceptedTransitions;
      break;
    case 'completed':
      lastTransitions = completedTransitions;
      break;
    case 'requested':
      lastTransitions = requestedTransitions;
      break;
    default:
      lastTransitions = getAllTransitionsForEveryProcess()
  }

  const apiQueryParams = {
    only: role === 'order' ? 'order' : 'sale',
    lastTransitions,
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType', 'publicData.location'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    perPage: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
