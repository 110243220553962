import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';

const { Money } = sdkTypes;

import css from './OrderBreakdown.module.css';

const LineItemTotalPrice = props => {
  const {
    transaction,
    isProvider,
    intl,
    refund,
  } = props;

  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const charge = transaction.attributes.metadata?.charge?.amount ?? 0;
  const transfer = transaction.attributes.metadata?.transfer?.amount ?? 0;
  const customerCommission = transaction.attributes.lineItems.find(
    ({code}) => code === "line-item/customer-commission"
  );
  const customerCommissionAmount = customerCommission ? customerCommission.lineTotal.amount : 0;
  const deposit = transaction.attributes.lineItems.find(
    ({code}) => code === "line-item/security-deposit"
  );
  const depositAmount = deposit ? deposit.lineTotal.amount : 0;
  const providerNotApprovedDeposit = transaction.attributes.transitions.find(
    ({transition}) => transition === "transition/not-approved"
  );
  const providerApprovedDeposit = transaction.attributes.transitions.find(
    ({transition}) => transition === "transition/approved"
  );


  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );


  const amount = isProvider
    ? transaction.attributes.payoutTotal.amount
    : transaction.attributes.payinTotal.amount;

  const currency = transaction.attributes.payoutTotal.currency;
  const refundAmount = refund ? refund.amount : 0;

  let resultAmount = isProvider
    ? amount -
      (refundAmount ? refundAmount : depositAmount) +
      transfer -
      charge +
      (charge ? customerCommissionAmount : 0) +
      (providerNotApprovedDeposit && depositAmount ? depositAmount : 0)
    : amount -
      refundAmount -
      (providerApprovedDeposit && depositAmount ? depositAmount : 0);

  const formattedTotalPrice = formatMoney(
    intl,
    new Money(resultAmount, currency)
  );

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
