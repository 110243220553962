import React from "react";
import { Form as FinalForm, FormSpy } from 'react-final-form';

import { FieldRadioButton } from '../../../components';

import css from '../OrderPanel.module.css'
import classNames from "classnames";

const TimeSwitch = (props) => {
  return <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        prices,
        handleSetTimeOption,
      } = fieldRenderProps;

      const pricesCopy = [...prices];

      pricesCopy.sort((a, b) => {
        const [aKey] = Object.keys(a);
        const [bKey] = Object.keys(b);
        const [aHours, aLabel] = aKey.split("-");
        const [bHours] = bKey.split("-");

        if (!aLabel) {
          return -1
        } else {
          return aHours - bHours;
        }

      });

      if (Object.keys(pricesCopy.at(-1) ?? {})[0] === 'Daily') {
        const daily = pricesCopy.pop();
        pricesCopy.unshift(daily);
      }

      return (
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <FormSpy onChange={handleSetTimeOption} />
          <div className={css.radioFieldGroup}>
            {pricesCopy.map((price) => {
              const [key] = Object.keys(price);
              const [hours, label] = key.split("-");

              const fieldKey = `${hours}${label ? " " + label : ""}`;

              return (
                <FieldRadioButton
                  key={fieldKey}
                  id={fieldKey}
                  name="time"
                  label={fieldKey}
                  value={fieldKey}
                  className={classNames(css.radioField, {[css.radioFieldDaily]: fieldKey === 'Daily'})}
                />
              )
            })}
          </div>
        </form>
      );
    }}
  />
}

export default TimeSwitch;
