import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, Button, FieldTextInput, IconClose } from '../../components';

import css from './AddFavorites.module.css';

const AddFavoritesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        form,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        values,
        onCloseModal
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || !values?.title;

      const closeModal = () => {
        onCloseModal(),
        form.change('title', null);
      }


      return (
        <Form className={classes} onSubmit={handleSubmit}>

          <div onClick={closeModal} className={css.listPopupCloseIcon}>
          <IconClose rootClassName={css.closeIcon} />
          </div>

          <FieldTextInput
            className={css.listPopupField}
            type="text"
            id="title"
            name="title"
            label={intl.formatMessage({
              id: 'FavoritesPage.titleLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'FavoritesPage.titlePlaceholder',
            })}
            validate={
              validators.composeValidators(
                validators.required(
                  intl.formatMessage({
                    id: 'FavoritesPage.titleRequired',
                  })
                ),
                validators.maxLength(
                  intl.formatMessage(
                    { id: 'FavoritesPage.titleTooLong' },
                    { maxLength: 50 }
                  ),
                  50
                )
              )
            }
          />
          <span className={css.listPopupCharacters}>
            <FormattedMessage
              id="FavoritesPage.charactersCounter"
              values={{ counter: !!values?.title ? `${values?.title?.length}/50` : '0/50' }}
            />
          </span>


          <div className={css.listPopupButtons}>
            <PrimaryButton className={css.listPopupButtonCancel} onClick={closeModal} inProgress={submitInProgress}>
              <FormattedMessage id="FavoritesPage.cancel" />
            </PrimaryButton>


            <Button className={css.listPopupButtonSave} type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="FavoritesPage.save" />
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

AddFavoritesFormComponent.defaultProps = { inProgress: false };

AddFavoritesFormComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const AddFavoritesForm = compose(injectIntl)(AddFavoritesFormComponent);
AddFavoritesForm.displayName = 'AddFavoritesForm';

export default AddFavoritesForm;
