import { storableError } from '../../util/errors';

import { requestDiscounts, createDiscount, requestRemoveDiscounts } from "../../util/api";

// ================ Action types ================ //

export const SAVE_DISCOUNT_REQUEST = 'app/DiscountDetailsPage/SAVE_DISCOUNT_REQUEST';
export const SAVE_DISCOUNT_SUCCESS = 'app/DiscountDetailsPage/SAVE_DISCOUNT_SUCCESS';
export const SAVE_DISCOUNT_ERROR = 'app/DiscountDetailsPage/SAVE_DISCOUNT_ERROR';

export const GET_DISCOUNTS_REQUEST = 'app/DiscountDetailsPage/GET_DISCOUNTS_REQUEST';
export const GET_DISCOUNTS_SUCCESS = 'app/DiscountDetailsPage/GET_DISCOUNTS_SUCCESS';
export const GET_DISCOUNTS_ERROR = 'app/DiscountDetailsPage/GET_DISCOUNTS_ERROR';

export const REMOVE_DISCOUNTS_REQUEST = 'app/DiscountDetailsPage/REMOVE_DISCOUNTS_REQUEST';
export const REMOVE_DISCOUNTS_SUCCESS = 'app/DiscountDetailsPage/REMOVE_DISCOUNTS_SUCCESS';
export const REMOVE_DISCOUNTS_ERROR = 'app/DiscountDetailsPage/REMOVE_DISCOUNTS_ERROR';
// ================ Reducer ================ //

const initialState = {
  saveDiscountError: null,
  saveDiscountBtnInProgress: false,
  discounts: [],
  fetchDiscountsError: null,
  removeDiscountError: null,
  removeDiscountBtnInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_DISCOUNT_REQUEST:
      return {
        ...state,
        saveDiscountError: null,
        saveDiscountBtnInProgress: true,
      };
    case SAVE_DISCOUNT_SUCCESS:
      return { ...state, saveDiscountBtnInProgress: false };
    case SAVE_DISCOUNT_ERROR:
      return { ...state, saveDiscountError: payload, saveDiscountBtnInProgress: false };

    case GET_DISCOUNTS_REQUEST:
      return { ...state, fetchDiscountsError: null };
    case GET_DISCOUNTS_SUCCESS:
      return { ...state, discounts: payload };
    case GET_DISCOUNTS_ERROR:
      return { ...state, fetchDiscountsError: payload };

    case REMOVE_DISCOUNTS_REQUEST:
      return { ...state, removeDiscountBtnInProgress: true, removeDiscountError: null };
    case REMOVE_DISCOUNTS_SUCCESS:
      return { ...state, removeDiscountBtnInProgress: false };
    case REMOVE_DISCOUNTS_ERROR:
      return { ...state, removeDiscountError: payload, removeDiscountBtnInProgress: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveDiscountRequest = () => ({ type: SAVE_DISCOUNT_REQUEST });
export const saveDiscountSuccess = () => ({ type: SAVE_DISCOUNT_SUCCESS });
export const saveDiscountError = error => ({
  type: SAVE_DISCOUNT_ERROR,
  payload: error,
  error: true,
});

export const getDiscountsRequest = () => ({ type: GET_DISCOUNTS_REQUEST });
export const getDiscountsSuccess = (payload) => ({
  type: GET_DISCOUNTS_SUCCESS,
  payload
});
export const getDiscountsError = error => ({
  type: GET_DISCOUNTS_ERROR,
  payload: error,
  error: true,
});

export const removeDiscountRequest = () => ({ type: REMOVE_DISCOUNTS_REQUEST });
export const removeDiscountSuccess = () => ({ type: REMOVE_DISCOUNTS_SUCCESS });
export const removeDiscountError = error => ({
  type: REMOVE_DISCOUNTS_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const saveDiscount = (params) => async (dispatch) => {
  dispatch(saveDiscountRequest());

  try {
    await createDiscount(params);
    dispatch(saveDiscountSuccess());
  } catch(e) {
    dispatch(saveDiscountError(storableError(e)));
  }
};

export const getDiscounts = () => async (dispatch) => {
  dispatch(getDiscountsRequest());

  try {
    const discounts = await requestDiscounts({});

    dispatch(getDiscountsSuccess(discounts.data));
  } catch(e) {
    dispatch(getDiscountsError(storableError(e)));
  }
};

export const removeDiscount = (id) => async (dispatch) => {
  dispatch(removeDiscountRequest());

  try {
    await requestRemoveDiscounts({ id });

    dispatch(removeDiscountSuccess());
  } catch(e) {
    dispatch(removeDiscountError(storableError(e)));
  }
}
