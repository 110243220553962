import React from 'react';
import Decimal from 'decimal.js';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = (lineItems, marketplaceCurrency) => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : marketplaceCurrency;
  return new Money(amount, currency);
};

/**
 * Checks if line item represents commission
 */
const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
};

/**
 * Returns non-commission, reversal line items
 */
const nonCommissionReversalLineItems = lineItems => {
  return lineItems.filter(item => !isCommission(item) && item.reversal);
};

const LineItemRefundMaybe = props => {
  const { lineItems, intl, marketplaceCurrency, isPartialRefund, transaction, isProvider } = props;

  // all non-commission, reversal line items
  const refundLineItems = nonCommissionReversalLineItems(lineItems);

  const providerDecline = transaction.attributes.transitions.find(
    ({ transition }) => transition === "transition/decline"
  );
  // charge exist when provider cancel booking
  const charge = transaction.attributes.metadata?.charge;
  const customerCommission = transaction.attributes.lineItems.find(
    ({code}) => code === "line-item/customer-commission"
  );
  const customerCommissionAmount = customerCommission ? customerCommission.lineTotal.amount : 0;

  const refund = lineItemsTotal(refundLineItems, marketplaceCurrency);
  const depositFee = refundLineItems.find(({code}) => code === "line-item/security-deposit-fee");
  const depositFeeAmount = depositFee ? depositFee.unitPrice.amount : 0;

  const formattedRefund = providerDecline
    ? formatMoney(intl, new Money(refund.amount.plus(depositFeeAmount), marketplaceCurrency))
    : isPartialRefund
    ? formatMoney(
        intl,
        new Money(
          (-isPartialRefund.amount + (charge && isProvider ? customerCommissionAmount : 0)),
          marketplaceCurrency
        )
      )
    : refundLineItems.length > 0
    ? formatMoney(intl, refund)
    : null;

  return formattedRefund ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.refund" />
      </span>
      <span className={css.itemValue}>{formattedRefund}</span>
    </div>
  ) : null;
};

LineItemRefundMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemRefundMaybe;
