import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import css from './IconFavorite.module.css';

const IconFavorite = props => {
  const { isFavorite, showText, showTextWithIcon, isOverlayPosition } = props

  const classes = classNames(
    css.root, 
    { [css.rootText]: showText }, 
    { [css.rootActive]: isFavorite },
    { [css.rootOverlay]: isOverlayPosition }
  );

  if (showTextWithIcon) {
    return (
      <div className={classes}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="15"
          fill="none"
          viewBox="0 0 17 15"
        >
          <path
            fillRule="evenodd"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.7"
            d="M14.084 2.093a3.73 3.73 0 00-5.277 0l-.719.72-.718-.72A3.731 3.731 0 002.093 7.37l.719.719 5.276 5.277 5.277-5.277.72-.72a3.73 3.73 0 000-5.276z"
            clipRule="evenodd"
          ></path>
        </svg>
        <FormattedMessage id={`${isFavorite ? "FavoritesPage.removeFromFavoritesShort" : "FavoritesPage.addToFavoritesShort"}`} />
      </div>
    )
  } else if (showText) {
    return (
      <div className={classes}>
        <FormattedMessage id={`${isFavorite ? "FavoritesPage.removeFromFavorites" : "FavoritesPage.addToFavorites"}`} />
      </div>
    )
  } else {
    return (
      <div className={classes}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="15"
          fill="none"
          viewBox="0 0 17 15"
        >
          <path
            fillRule="evenodd"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.7"
            d="M14.084 2.093a3.73 3.73 0 00-5.277 0l-.719.72-.718-.72A3.731 3.731 0 002.093 7.37l.719.719 5.276 5.277 5.277-5.277.72-.72a3.73 3.73 0 000-5.276z"
            clipRule="evenodd"
          ></path>
        </svg>
      </div>
    );
  }

};

IconFavorite.defaultProps = {
  rootClassName: null,
  className: null,
  fillColor: null,
};

IconFavorite.propTypes = {
  rootClassName: string,
  className: string,
  fillColor: string,
};

export default IconFavorite;
