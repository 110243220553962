import {types as sdkTypes} from "../../util/sdkLoader";

const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;

const getBounds = (neLat, neLng, swLat, swLng) => {
  return new SDKLatLngBounds(
    new SDKLatLng(neLat, neLng),
    new SDKLatLng(swLat, swLng)
  );
}

const getLatLng = (lat, lng) => {
  return new SDKLatLng(lat, lng)
}


export const statesOptions = [
  {
    key: 'alabama',
    keyUA: 'алабама',
    label: 'Alabama',
    value: 'Alabama',
    origin: getLatLng(32.3182314, -86.902298),
    bounds: getBounds(35.00802795381666, -84.88824602981809, 30.14442496830631, -88.47322693767974),
    id: 0,
  },
  {
    key: 'alaska',
    keyUA: 'аляска',
    label: 'Alaska',
    value: 'Alaska',
    id: 1,
    origin: getLatLng(63.588753, -154.4930619),
    bounds: getBounds(71.43978604746711, -129.9741939330935, 51.17509196926044, 172.3478460274148)
  },
  {
    key: 'arizona',
    keyUA: 'аризона',
    label: 'Arizona',
    value: 'Arizona',
    id: 2,
    origin: getLatLng(34.0489281, -111.0937311),
    bounds: getBounds(37.00372505965424, -109.045171916046, 31.33234477848764, -114.8163228550557)
  },
  {
    key: 'arkansas',
    keyUA: 'арканзас',
    label: 'Arkansas',
    value: 'Arkansas',
    id: 3,
    origin: getLatLng(35.20105, -91.8318334),
    bounds: getBounds(36.49974906092542, -89.64099703456495, 33.00428206756398, -94.61785899197491)
  },
  {
    key: 'california',
    keyUA: 'калифорния',
    label: 'California',
    value: 'California',
    id: 4,
    origin: getLatLng(36.778261, -119.4179324),
    bounds: getBounds(42.00950300443976, -114.1312110028529, 32.52883196043871, -124.4820030490848)
  },
  {
    key: 'colorado',
    keyUA: 'колорадо',
    label: 'Colorado',
    value: 'Colorado',
    id: 5,
    origin: getLatLng(),
    bounds: getBounds(41.00344393187095, -102.0415219882994, 36.99244903229619, -109.0602039722579)
  },
  {
    key: 'connecticut',
    keyUA: 'коннектикут',
    label: 'Connecticut',
    value: 'Connecticut',
    id: 6,
    origin: getLatLng(39.5500507, -105.7820674),
    bounds: getBounds(42.05051096606773, -71.78723902917415, 40.95094295977581, -73.7277749818916)
  },
  {
    key: 'delaware',
    keyUA: 'делавер',
    label: 'Delaware',
    value: 'Delaware',
    id: 7,
    origin: getLatLng(38.9108325, -75.52766989999999),
    bounds: getBounds(39.8395160495874, -74.98416498442569, 38.45113202475723, -75.7890230677169)
  },
  {
    key: 'florida',
    keyUA: 'флорида',
    label: 'Florida',
    value: 'Florida',
    id: 8,
    origin: getLatLng(27.6648274, -81.5157535),
    bounds: getBounds(32.3607674, -77.8049338, 22.8285924, -89.8042682)
  },
  {
    key: 'georgia',
    keyUA: 'джорджия',
    label: 'Georgia',
    value: 'Georgia',
    id: 9,
    origin: getLatLng(32.1574351, -82.90712300000001),
    bounds: getBounds(35.00130301500388, -80.78295998100835, 30.35575693442233, -85.60516493123394)
  },
  {
    key: 'hawaii',
    keyUA: 'гавайи',
    label: 'Hawaii',
    value: 'Hawaii',
    id: 10,
    origin: getLatLng(19.8986819, -155.6658568),
    bounds: getBounds(22.37, -154.48, 18.55, -160.53)
  },
  {
    key: 'idaho',
    keyUA: 'айдахо',
    label: 'Idaho',
    value: 'Idaho',
    id: 11,
    origin: getLatLng(44.0682019, -114.7420408),
    bounds: getBounds(49.00084608942538, -111.0434949886877, 41.98818193370447, -117.24302696742)
  },
  {
    key: 'illinois',
    keyUA: 'иллинойс',
    label: 'Illinois',
    value: 'Illinois',
    id: 12,
    origin: getLatLng(40.6331249, -89.3985283),
    bounds: getBounds(42.50848094838776, -87.01993496220028, 36.9702980460126, -91.51307901663446)
  },
  {
    key: 'indiana',
    keyUA: 'индиана',
    label: 'Indiana',
    value: 'Indiana',
    id: 13,
    origin: getLatLng(40.5512165, -85.60236429999999),
    bounds: getBounds(41.76136800286847, -84.78453506641252, 37.77167200487875, -88.09789199026922)
  },
  {
    key: 'iowa',
    keyUA: 'айова',
    label: 'Iowa',
    value: 'Iowa',
    id: 14,
    origin: getLatLng(41.8780025, -93.097702),
    bounds: getBounds(43.50119605320161, -90.1400609349535, 40.3754400188, -96.63948492461773)
  },
  {
    key: 'kansas',
    keyUA: 'канзас',
    label: 'Kansas',
    value: 'Kansas',
    id: 15,
    origin: getLatLng(39.011902, -98.4842465),
    bounds: getBounds(40.00316596776067, -94.58838702801843, 36.99301593983594, -102.0517688242823)
  },
  {
    key: 'kentucky',
    keyUA: 'кентукки',
    label: 'Kentucky',
    value: 'Kentucky',
    id: 16,
    origin: getLatLng(37.8393332, -84.2700179),
    bounds: getBounds(39.14773205652311, -81.96478807948522, 36.4970579515975, -89.57120303316137)
  },
  {
    key: 'louisiana',
    keyUA: 'луизиана',
    label: 'Louisiana',
    value: 'Louisiana',
    id: 17,
    origin: getLatLng(30.5190775, -91.5208624),
    bounds: getBounds(33.01962011001316, -88.75838798242145, 28.85512707038328, -94.04335203188151)
  },
  {
    key: 'maine',
    keyUA: 'мейн',
    label: 'Maine',
    value: 'Maine',
    id: 18,
    origin: getLatLng(45.253783, -69.4454689),
    bounds: getBounds(47.45983298930241, -66.88544396533057, 42.91712599352773, -71.0839278031257)
  },
  {
    key: 'maryland',
    keyUA: 'мериленд',
    label: 'Maryland',
    value: 'Maryland',
    id: 19,
    origin: getLatLng(39.0457549, -76.64127119999999),
    bounds: getBounds(39.7230370307816, -74.98628191214425, 37.88660501609504, -79.4876510639216)
  },
  {
    key: 'massachusetts',
    keyUA: 'массачусетс',
    label: 'Massachusetts',
    value: 'Massachusetts',
    id: 20,
    origin: getLatLng(42.4072107, -71.3824374),
    bounds: getBounds(42.88677799555013, -69.85886097535136, 41.18705303854487, -73.50821007655624)
  },
  {
    key: 'michigan',
    keyUA: 'мичиган',
    label: 'Michigan',
    value: 'Michigan',
    id: 21,
    origin: getLatLng(44.3148443, -85.60236429999999),
    bounds: getBounds(48.30561094582951, -82.12280500730516, 41.6961179803949, -90.4183919308845)
  },
  {
    key: 'minnesota',
    keyUA: 'минесота',
    label: 'Minnesota',
    value: 'Minnesota',
    id: 22,
    origin: getLatLng(46.729553, -94.6858998),
    bounds: getBounds(49.38447202078465, -89.48381217787039, 43.49936096179093, -97.23909299996531)
  },
  {
    key: 'mississippi',
    keyUA: 'мисиссипи',
    label: 'Mississippi',
    value: 'Mississippi',
    id: 23,
    origin: getLatLng(32.3546679, -89.3985283),
    bounds: getBounds(34.99609900841086, -88.09788796587989, 30.13984496143205, -91.65500906197607)
  },
  {
    key: 'missouri',
    keyUA: 'миссури',
    label: 'Missouri',
    value: 'Missouri',
    id: 24,
    origin: getLatLng(37.9642529, -91.8318334),
    bounds: getBounds(40.61363997509282, -89.09896800110899, 35.99568492252557, -95.77470400212587)
  },
  {
    key: 'montana',
    keyUA: 'монтана',
    label: 'Montana',
    value: 'Montana',
    id: 25,
    origin: getLatLng(46.8796822, -110.3625658),
    bounds: getBounds(49.00111167030684, -104.0396939878996, 44.35791500658805, -116.0491529136954)
  },
  {
    key: 'nebraska',
    keyUA: 'небраска',
    label: 'Nebraska',
    value: 'Nebraska',
    id: 26,
    origin: getLatLng(41.4925374, -99.9018131),
    bounds: getBounds(43.00170702349276, -95.30829000605853, 39.99993201807688, -104.0535139593889)
  },
  {
    key: 'nevada',
    keyUA: 'невада',
    label: 'Nevada',
    value: 'Nevada',
    id: 27,
    origin: getLatLng(38.8026097, -116.419389),
    bounds: getBounds(42.00184196464553, -114.0394609940115, 35.00185701541201, -120.0064729345855)
  },
  {
    key: 'new hampshire',
    keyUA: 'нью-хемпшир',
    label: 'New Hampshire',
    value: 'New Hampshire',
    id: 28,
    origin: getLatLng(43.1938516, -71.5723953),
    bounds: getBounds(45.30577773954853, -70.57509397395282, 42.69704196007883, -72.55712395870282)
  },
  {
    key: 'new jersey',
    keyUA: 'нью-джерси',
    label: 'New Jersey',
    value: 'New Jersey',
    id: 29,
    origin: getLatLng(40.0583238, -74.4056612),
    bounds: getBounds(41.35760698838924, -73.88505995655244, 38.78865697747964, -75.56358608752295)
  },
  {
    key: 'new mexico',
    keyUA: 'нью-мехико',
    label: 'New Mexico',
    value: 'New Mexico',
    id: 30,
    origin: getLatLng(34.9727305, -105.0323635),
    bounds: getBounds(37.00023295164024, -103.0020429667444, 31.33216180712133, -109.0504310076264)
  },
  {
    key: 'new york',
    keyUA: 'нью-йорк',
    label: 'New York',
    value: 'New York',
    id: 31,
    origin: getLatLng(40.7127753, -74.0059728),
    bounds: getBounds(40.91757705070789, -73.70027206817629, 40.47739906045452, -74.25908991427882)
  },
  {
    key: 'north carolina',
    keyUA: 'северная каролина',
    label: 'North Carolina',
    value: 'North Carolina',
    id: 32,
    origin: getLatLng(35.7595731, -79.01929969999999),
    bounds: getBounds(36.58813706643755, -75.40011901176226, 33.75287800466285, -84.32182095701926)
  },
  {
    key: 'north dakota',
    keyUA: 'северная дакота',
    label: 'North Dakota',
    value: 'North Dakota',
    id: 33,
    origin: getLatLng(47.5514926, -101.0020119),
    bounds: getBounds(49.00055849725752, -96.5544110772682, 45.93507193820341, -104.0490120237419)
  },
  {
    key: 'ohio',
    keyUA: 'огайо',
    label: 'Ohio',
    value: 'Ohio',
    id: 34,
    origin: getLatLng(40.4172871, -82.90712300000001),
    bounds: getBounds(42.32337404096916, -80.51870503609469, 38.40342287692288, -84.8203050709824)
  },
  {
    key: 'oklahoma',
    keyUA: 'оклахома',
    label: 'Oklahoma',
    value: 'Oklahoma',
    id: 35,
    origin: getLatLng(35.0077519, -97.092877),
    bounds: getBounds(37.00231205981126, -94.43101393697654, 33.61578697960584, -103.0024129458349)
  },
  {
    key: 'oregon',
    keyUA: 'орегон',
    label: 'Oregon',
    value: 'Oregon',
    id: 36,
    origin: getLatLng(43.8041334, -120.5542012),
    bounds: getBounds(46.29909902539971, -116.4632619690902, 41.99208194682079, -124.7035410212307)
  },
  {
    key: 'pennsylvania',
    keyUA: 'пенсильвания',
    label: 'Pennsylvania',
    value: 'Pennsylvania',
    id: 37,
    origin: getLatLng(41.2033216, -77.1945247),
    bounds: getBounds(42.51416576510771, -74.68956094709924, 39.71979900602428, -80.51984850253436)
  },
  {
    key: 'rhode island',
    keyUA: 'род-айленд',
    label: 'Rhode Island',
    value: 'Rhode Island',
    id: 38,
    origin: getLatLng(41.5800945, -71.4774291),
    bounds: getBounds(42.01879907365016, -71.08857091054786, 41.0958339651487, -71.90725806100225)
  },
  {
    key: 'south carolina',
    keyUA: 'южная каролина',
    label: 'South Carolina',
    value: 'South Carolina',
    id: 39,
    origin: getLatLng(33.836081, -81.1637245),
    bounds: getBounds(35.21548492898592, -78.49930092531599, 31.9959539649131, -83.35392799812386)
  },
  {
    key: 'south dakota',
    keyUA: 'южная дакота',
    label: 'South Dakota',
    value: 'South Dakota',
    id: 40,
    origin: getLatLng(43.9695148, -99.9018131),
    bounds: getBounds(45.94537703063709, -96.43647197161877, 42.47968601024274, -104.0578790692265)
  },
  {
    key: 'tennessee',
    keyUA: 'тенеси',
    label: 'Tennessee',
    value: 'Tennessee',
    id: 41,
    origin: getLatLng(35.5174913, -86.5804473),
    bounds: getBounds(36.67825503602104, -81.64690001182882, 34.98292391961682, -90.31049105785706)
  },
  {
    key: 'texas',
    keyUA: 'техас',
    label: 'Texas',
    value: 'Texas',
    id: 42,
    origin: getLatLng(31.9685988, -99.9018131),
    bounds: getBounds(36.50112613904738, -93.50803894473373, 25.83711645856708, -106.6456460547471)
  },
  {
    key: 'utah',
    keyUA: 'юта',
    label: 'Utah',
    value: 'Utah',
    id: 43,
    origin: getLatLng(39.3209801, -111.0937311),
    bounds: getBounds(42.00170194719211, -109.0415719854012, 36.99765682253983, -114.0528850475383)
  },
  {
    key: 'vermont',
    keyUA: 'вермонт',
    label: 'Vermont',
    value: 'Vermont',
    id: 45,
    origin: getLatLng(44.5588028, -72.57784149999999),
    bounds: getBounds(45.01665799286757, -71.46504701039785, 42.72693297349642, -73.43790497922724)
  },
  {
    key: 'virginia',
    keyUA: 'вирджиния',
    label: 'Virginia',
    value: 'Virginia',
    id: 46,
    origin: getLatLng(37.4315734, -78.6568942),
    bounds: getBounds(39.4660119830118, -75.16643504475577, 36.54085192914776, -83.67539507062487)
  },
  {
    key: 'washington',
    keyUA: 'вашингтон',
    label: 'Washington',
    value: 'Washington',
    id: 47,
    origin: getLatLng(47.7510741, -120.7401386),
    bounds: getBounds(49.00249453041775, -116.916071080042, 45.54354101516995, -124.8489739457119)
  },
  {
    key: 'west virginia',
    keyUA: 'западная вирджиния',
    label: 'West Virginia',
    value: 'West Virginia',
    id: 48,
    origin: getLatLng(38.5976262, -80.4549026),
    bounds: getBounds(40.63880098741526, -77.71951897317682, 37.2015399411757, -82.64459104447006)
  },
  {
    key: 'wisconsin',
    keyUA: 'висконсин',
    label: 'Wisconsin',
    value: 'Wisconsin',
    id: 49,
    origin: getLatLng(43.7844397, -88.7878678),
    bounds: getBounds(47.30982195141704, -86.2495480246244, 42.49171994804539, -92.88943307713289)
  },
  {
    key: 'wyoming',
    keyUA: 'вайоминг',
    label: 'Wyoming',
    value: 'Wyoming',
    id: 50,
    origin: getLatLng(43.0759678, -107.2902839),
    bounds: getBounds(45.00581503797179, -104.0522449418437, 40.99477203798426, -111.0545580542501)
  },
];
