/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';
import Decimal from "decimal.js";

import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_UNIT_TYPES,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';

import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemShippingFeeMaybe from './LineItemShippingFeeMaybe';
import LineItemPickupFeeMaybe from './LineItemPickupFeeMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import LineItemSecurityDepositMaybe from "./LineItemSecurityDepositMaybe";
import LineItemProviderCancel from './LineItemProviderCancel';
import LineItemDepositRefund from './LineItemDepositRefund';
import LineItemCustomerCancelBooking from './LineItemCustomerCancelBooking';

import css from './OrderBreakdown.module.css';

const formatCommissionsAfterRefund = (lineItems) => {
  return lineItems.reduce((result, item) => {
    const index = result.findIndex(
      ({ code, reversal }) => item.code === code && item.reversal === reversal
    );

    if (index >= 0 && result[index].unitPrice.amount > item.unitPrice.amount) {
      result.splice(index, 1);
    }

    return [...result, item];
  }, []);
}

export const OrderBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    transaction,
    booking,
    intl,
    dateType,
    timeZone,
    currency,
    marketplaceName,
    timeOption,
    securityDeposit
  } = props;

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';
  let lineItems = transaction.attributes.lineItems;
  const unitLineItem = lineItems?.find(
    item => LISTING_UNIT_TYPES.includes(item.code) && !item.reversal
  );
  // Line-item code that matches with base unit: day, night, hour, item
  const lineItemUnitType = unitLineItem?.code;

  const isPartialRefund = transaction?.attributes.metadata?.refund;

  const refundLineItems = (isPartialRefund?.lineItems ?? []).map((item) => {
    const { lineTotal, percentage, unitPrice, quantity, ...rest } = item;

    return {
      ...rest,
      lineTotal: new Money(lineTotal.amount, lineTotal.currency),
      unitPrice: new Money(unitPrice.amount, unitPrice.currency),
      ...(percentage ? { percentage: new Decimal(percentage) } : {}),
      ...(quantity ? { quantity: new Decimal(quantity) } : {}),
    }
  });
  lineItems = formatCommissionsAfterRefund(lineItems.concat(refundLineItems));

  const hasCommissionLineItem = lineItems.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const classes = classNames(rootClassName || css.root, className);

  const cancellationPolicyPercentage =
    transaction?.listing?.attributes?.publicData?.cancellationPolicyPercentage;

  const refund = transaction?.attributes?.metadata?.refund;
  const cancelledBookingPaymentIntent = transaction?.attributes?.metadata?.cancelledBookingPaymentIntent;

  /**
   * OrderBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemShippingFeeMaybe: prints the shipping fee (combining additional fee of
   * additional items into it).
   *
   * LineItemShippingFeeRefundMaybe: prints the amount of refunded shipping fee
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by this template doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  return (
    <div className={classes}>
      <LineItemBookingPeriod
        booking={booking}
        code={lineItemUnitType}
        dateType={dateType}
        timeZone={timeZone}
      />

      <LineItemBasePriceMaybe
        lineItems={lineItems}
        code={lineItemUnitType}
        intl={intl}
        timeOption={timeOption}
        transaction={transaction}
        booking={booking}
      />
      <LineItemShippingFeeMaybe lineItems={lineItems} intl={intl} />
      <LineItemPickupFeeMaybe lineItems={lineItems} intl={intl} />
      <LineItemUnknownItemsMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        intl={intl}
        refund={refund}
        cancelledBookingPaymentIntent={cancelledBookingPaymentIntent}
        tx={transaction}
      />

      <LineItemSecurityDepositMaybe
        securityDeposit={securityDeposit}
        intl={intl}
        transaction={transaction}
        isProvider={isProvider}
      />

      <LineItemSubTotalMaybe
        lineItems={lineItems}
        code={lineItemUnitType}
        userRole={userRole}
        intl={intl}
        marketplaceCurrency={currency}
        refund={refund}
        cancelledBookingPaymentIntent={cancelledBookingPaymentIntent}
      />

      <LineItemCustomerCommissionMaybe
        transaction={transaction}
        lineItems={lineItems}
        isCustomer={isCustomer}
        marketplaceName={marketplaceName}
        intl={intl}
      />
      <LineItemCustomerCommissionRefundMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        marketplaceName={marketplaceName}
        intl={intl}
      />

      <LineItemProviderCommissionMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        marketplaceName={marketplaceName}
        intl={intl}
      />
      <LineItemProviderCommissionRefundMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        marketplaceName={marketplaceName}
        intl={intl}
      />

      <LineItemCustomerCancelBooking
        intl={intl}
        refund={refund}
        currency={currency}
        securityDeposit={securityDeposit}
        transaction={transaction}
        isProvider={isProvider}
      />
      <LineItemProviderCancel
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
        marketplaceCurrency={currency}
      />
       <LineItemRefundMaybe
        lineItems={lineItems}
        intl={intl}
        marketplaceCurrency={currency}
        isPartialRefund={isPartialRefund}
        transaction={transaction}
        isProvider={isProvider}
      />

      <LineItemDepositRefund
        intl={intl}
        transaction={transaction}
      />

      <LineItemTotalPrice
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
        isPartialRefund={isPartialRefund}
        percentage={cancellationPolicyPercentage}
        securityDeposit={securityDeposit}
        refund={refund}
        cancelledBookingPaymentIntent={cancelledBookingPaymentIntent}
      />

      {hasCommissionLineItem ? (
        <span className={css.feeInfo}>
          <FormattedMessage id="OrderBreakdown.commissionFeeNote" />
        </span>
      ) : null}
    </div>
  );
};

OrderBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  booking: null,
  dateType: null,
};

OrderBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,
  userRole: oneOf(['customer', 'provider']).isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
};

const OrderBreakdown = injectIntl(OrderBreakdownComponent);

OrderBreakdown.displayName = 'OrderBreakdown';

export default OrderBreakdown;
