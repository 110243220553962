import React, { useState } from "react";

import { Button, InlineTextButton } from "../../../../components";

import css from '../../SocialSharePopup.module.css';

const StarIcon = ({fill}) => {
  return (
    <svg className={css.starIcon} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9676 5.08744C11.8891 4.87255 11.684 4.72747 11.4539 4.72747H7.50286L6.52547 1.30552C6.45784 1.07099 6.24404 0.909546 5.99969 0.909546C5.75644 0.909546 5.54154 1.07099 5.475 1.30552L4.49652 4.72747H0.545519C0.315353 4.72747 0.111367 4.87255 0.0328268 5.08744C-0.0457133 5.30343 0.0197368 5.5445 0.196452 5.69286L3.17007 8.17123L1.67017 12.1724C1.58618 12.3971 1.65926 12.6502 1.85016 12.7964C2.03887 12.9425 2.30394 12.9469 2.49811 12.8073L5.99969 10.3071L9.50127 12.8073C9.69653 12.9469 9.96051 12.9425 10.1503 12.7964C10.3412 12.6502 10.4132 12.3971 10.3292 12.1724L8.82932 8.17123L11.804 5.69286C11.9807 5.5445 12.0451 5.30343 11.9676 5.08744Z" fill={fill} />
    </svg>
  )
}

const ListingEmbed = ({
  copyEmbed,
  handleCloseEmbed,
  embed,
  img,
  title,
  reviewRate,
  reviewCount,
  intl,
}) => {
  const [embedSaved, setEmbedSaved] = useState(false);

  const handleCopyEmbed = () => {
    setEmbedSaved(true);
    copyEmbed();
    setTimeout(() => setEmbedSaved(false), 1000);
  }

  return (
    <div>
      <div className={css.embedContent}>
        <div className={css.column}>
          <h1 className={css.title}>Embed this stay</h1>
          <h2 className={css.textAreaLabel}>Copy and paste the following HTML into your website code:</h2>
          <div className={css.textWrapper}>
            <textarea defaultValue={embed} rows={4} cols={60}/>
          </div>
          <div>
            {embedSaved
              ?  <span className={css.successText}>Copied!</span>
              : (
                  <Button onClick={handleCopyEmbed} className={css.btn}>
                    Copy HTML
                  </Button>
                )
            }
          </div>
        </div>
        <div className={css.column}>
          <div className={css.listingBlock}>
            <span className={css.preview}>Preview</span>
            <div><img src={img} alt="listing" className={css.previewImg}/></div>
            <div>
              <p className={css.listingTitle}>
                <span>{title}</span>
                {" "}&#8226;{" "}
                <StarIcon fill="#000" />
                <span>{reviewRate}</span>
              </p>
              <p className={css.reviewRaiting}>
                <StarIcon fill="#E4696A" />
                <span>{reviewRate}</span>
                {intl.formatMessage({ id: "ProviderListings.reviewRate" }, { count: reviewCount })}
              </p>
            </div>
            <div className={css.btnWrapper}>
              <InlineTextButton onClick={() => {}}>
                View On TRBO
              </InlineTextButton>
            </div>
          </div>
        </div>
      </div>
      <div className={css.backBtnWrapper}>
        <InlineTextButton onClick={handleCloseEmbed} className={css.backBtn}>
          Go back
        </InlineTextButton>
      </div>
    </div>
  )
}

export default ListingEmbed;
