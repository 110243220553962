import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import logo from './logo-image.png';
import css from './IconLogoCustom.module.css';

const IconLogoCustom = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <img src={logo} className={classes} alt="TRBO Logo" />
  );
};

IconLogoCustom.defaultProps = { className: null, rootClassName: null };

const { string } = PropTypes;

IconLogoCustom.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLogoCustom;
