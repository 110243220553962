import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconUser.module.css';

const IconUser = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentcolor"
      ariaHidden="true"
      display="block"
      viewBox="0 0 32 32"
    >
      <path d="M16 1a15 15 0 110 30 15 15 0 010-30zm0 8a5 5 0 00-2 9.58v2.1l-.15.03a11 11 0 00-6.94 4.59C9.26 27.59 12.46 29 16 29s6.74-1.41 9.09-3.7a11 11 0 00-6.93-4.59l-.16-.03v-2.1a5 5 0 003-4.35V14a5 5 0 00-5-5zm0-6A13 13 0 005.56 23.75a13.02 13.02 0 015.54-4.3l.35-.13-.02-.02A7 7 0 019 14.27V14a7 7 0 1111.78 5.12l-.23.2.04.02c2.33.88 4.36 2.41 5.85 4.4A13 13 0 0016 3z"></path>
    </svg>
  );
};

IconUser.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconUser.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconUser;
