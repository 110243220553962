import {transitions} from '../transactions/transactionProcessBooking';

export const acceptedTransitions = [
  transitions.ACCEPT,
  transitions.ACCEPT_NO_DEPOSIT,
  transitions.ACCEPT_INSTANT,
  transitions.ACCEPT_INSTANT_NO_DEPOSIT,
  transitions.OPERATOR_ACCEPT,
  transitions.OPERATOR_ACCEPT_NO_DEPOSIT,
  transitions.AUTO_COMPLETE,
];
