import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';

import css from './OrderBreakdown.module.css';

const LineItemDepositRefund = props => {
  const { transaction, intl } = props;

  const deposit = transaction.attributes.lineItems.find(({code}) => code === "line-item/security-deposit");
  const bookingApproved = transaction.attributes.transitions.find(
    ({transition}) => transition === "transition/approved"
  );

  if (!deposit || !bookingApproved) {
    return null;
  }

  const depositAmount = deposit.lineTotal;

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.depositRefund" />
      </span>
      <span className={css.itemValue}>{formatMoney(intl, depositAmount)}</span>
    </div>
  );
};


export default LineItemDepositRefund;
