import React from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import {
  LinkedLogo,
  IconClose,
  NamedLink
} from '../../components';

import { H1 } from '../../containers/PageBuilder/Primitives/Heading';
import { fallbackSections } from './FallbackPage';

import css from './LandingPopup.module.css';

const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../../containers/PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

const LandingPopup = props => {
  const {
    className,
    rootClassName,
    onClosePopup,
    inProgress,
    error,
    data
  } = props;
  
  if (inProgress || !data) {
    return null;
  }

  const classes = classNames(rootClassName || css.root, className);

  // We don't want to add h1 heading twice to the HTML (SEO issue).
  // Modal's header is mapped as h2
  const hasContent = data => typeof data?.content === 'string';
  const exposeContentAsChildren = data => {
    return hasContent(data) ? { children: data.content } : {};
  };
  const CustomHeading1 = props => <H1 as="h2" {...props} />;

  const hasData = error === null && data;
  const sectionsData = hasData ? data : fallbackSections;

  return (
    <div className={classes}>
      <main className={css.main}>
        <SectionBuilder
          {...data}
          options={{
            fieldComponents: {
              heading1: { component: CustomHeading1, pickValidProps: exposeContentAsChildren },
            },
            isInsideContainer: true,
          }}
          withCustomHeader
          onClosePopup={onClosePopup}
        />
      </main>
    </div>
  );
};


const { string } = PropTypes;

LandingPopup.defaultProps = {
  className: null,
  rootClassName: null,
};

LandingPopup.propTypes = {
  className: string,
  rootClassName: string,
};

export default LandingPopup;
