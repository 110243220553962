import React from 'react';
import classnames from 'classnames';
import draftToHtml from 'draftjs-to-html';

import css from './DarftContent.module.css';

const DarftContent = props => {
  const { draftContent, className } = props;

  if (!draftContent) {
    return null
  }

  const draftContentToString = JSON.stringify(draftContent);
  const draftContentToObject = JSON.parse(draftContentToString);

  const validContent = draftContentToObject.indexOf("blocks") !== -1;

  if (validContent) {
    let html;
    try {
      const formattedDraftContent = draftContent
        .replace(/(\"fontfamily-[^\}]*)/ig, '"fontfamily-Montserrat"')
      ;
      html = draftToHtml(JSON.parse(formattedDraftContent));
    } catch (e) {
      html = draftToHtml(JSON.parse(draftContent));
    }

    return (
      <div className={classnames(css.root, className)}>
        <div
          className="content"
          dangerouslySetInnerHTML={{__html: html.replace(/<a href=/ig, '<a rel="nofollow" href=')}}
        />
      </div>
    )
  } else {
    return (
      <p className={classnames(css.root, className)}>
        {draftContent}
      </p>
    )
  }
};

export default DarftContent;
