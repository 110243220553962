import { storableError } from '../../util/errors';
import { contactUs } from '../../util/api';



// ================ Action types ================ //

export const CONTACT_US_REQUEST = 'app/ContactUsPage/CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'app/ContactUsPage/CONTACT_US_SUCCESS';
export const CONTACT_US_ERROR = 'app/ContactUsPage/CONTACT_US_ERROR';

// ================ Reducer ================ //

const initialState = {
  contactUsSuccess: false,
  contactUsInProgress: false,
  contactUsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CONTACT_US_REQUEST:
      return { ...state, contactUsInProgress: true, contactUsSuccess: false, contactUsError: null };
    case CONTACT_US_SUCCESS:
      return { ...state, contactUsInProgress: false, contactUsSuccess: true, };
    case CONTACT_US_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, contactUsInProgress: false,  contactUsSuccess: false, contactUsError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const contactUsRequest = () => ({ type: CONTACT_US_REQUEST });

export const contactUsSuccess = () => ({ type: CONTACT_US_SUCCESS });

export const contactUsError = e => ({
  type: CONTACT_US_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const sendContactUs = ({email, name, comment}) => (dispatch) => {

  dispatch(contactUsRequest());

  const contactParams = {
    email,
    name,
    text: comment,
  };

  return contactUs(contactParams)
    .then(response => {
      dispatch(contactUsSuccess(true));
    })
    .then(response => {
      setTimeout(() => {
        dispatch(contactUsSuccess(false));
      }, 5000)
    })
    .catch(e => {
      dispatch(contactUsError(storableError(e)));
    });
};
