import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

import { transitions } from '../../transactions/transactionProcessBooking';

const { Money } = sdkTypes;
import css from './OrderBreakdown.module.css';

const LineItemCustomerCancelBooking = props => {
  const {
    intl,
    // refund,
    currency,
    // securityDeposit,
    transaction,
    isProvider,
  } = props;

  const refund = transaction?.attributes?.metadata?.refund?.amount ?? 0;
  if (!refund) return null;

  const bookingAmount = transaction?.attributes.lineItems
    .find(({code}) => code === "line-item/hour" || code === "line-item/day");
  const additionalServices = transaction?.attributes.lineItems
    .filter(({code}) => code.endsWith("additional"));

  const cancellationPolicyPercentage = transaction?.listing?.attributes?.publicData?.cancellationPolicyPercentage;
  const customerCancelBooking = transaction?.attributes.transitions
    .find(({transition}) =>
      transition === transitions.CUSTOMER_CANCEL ||
      transition === transitions.CUSTOMER_CANCEL_FULL_REFUND ||
      transition === transitions.CUSTOMER_CANCEL_NO_DEPOSIT ||
      transition === transitions.CUSTOMER_CANCEL_FULL_REFUND_NO_DEPOSIT);

  const amountWithServices =
    bookingAmount.lineTotal.amount +
    additionalServices.reduce((acc, cur) => acc + cur.lineTotal.amount , 0);

  const cancellationFee = cancellationPolicyPercentage
    ? (amountWithServices * +cancellationPolicyPercentage) / 100
    : 0;
  const amount = amountWithServices - cancellationFee;

  return !isProvider && refund && customerCancelBooking? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.customerCancelBooking" />
      </span>
      <span className={css.itemValue}>
        {formatMoney(intl, new Money(amount, currency))}
      </span>
    </div>
  ) : null;
};

export default LineItemCustomerCancelBooking;
