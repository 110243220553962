import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;
import css from './OrderBreakdown.module.css';

const LineItemSecurityDepositMaybe = props => {
  const { securityDeposit, intl } = props;

  if (!securityDeposit) return null;

  // const depositFeeLineItem = transaction.attributes.lineItems
  //   .find(({code}) => code === "line-item/security-deposit-fee");
  // const depositFee = depositFeeLineItem ? depositFeeLineItem.lineTotal.amount : 0

  const {
    amount,
    currency,
  } = securityDeposit[0]?.price ?? {};

  return (
    <>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="ListingPage.listingDepositTitle" />
        </span>
        <span className={css.itemValue}>{formatMoney(intl, new Money(amount, currency))}</span>
      </div>
      {/* {depositFee && isProvider ? (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="ListingPage.providerReturnDepositFee" />
          </span>
          <span className={css.itemValue}>{formatMoney(intl, new Money(Math.abs(depositFee), currency))}</span>
        </div>
      ) : null} */}
    </>
  );
};

LineItemSecurityDepositMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSecurityDepositMaybe;
