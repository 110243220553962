import React from 'react';

import css from './ToggleButton.module.css';

const ToggleButton = ({handleToggleInstantBooking, isInstant}) => {
  return (
    <div className={css.checkboxContainer}>
      {isInstant ? (
        <input
          type="checkbox"
          checked={true}
          id="switch"
          className={css.input}
          onChange={handleToggleInstantBooking}
        />) : (
          <input
            type="checkbox"
            id="switch"
            className={css.input}
            onChange={handleToggleInstantBooking}
          />)}
      <label htmlFor="switch" className={css.label}>Toggle</label>
    </div>
  )
}

export default ToggleButton;
