import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { FavoritesListCards, IconClose } from '../../components';

import css from './AddFavorites.module.css';

const AddToExistingFavorites = props => {

  const { favorites, onCloseModal, showNewListPopup, updateExistingList } = props;

  if(!favorites?.length) return null

  return (

    <div className={css.favoritesListHolder}>
      <div onClick={onCloseModal} className={css.listPopupCloseIcon}>
        <IconClose rootClassName={css.closeIcon} />
      </div>

      <FavoritesListCards 
        favorites={favorites}
        updateExistingList={updateExistingList}
      />

      <div className={css.listPopupButtonCreateNew} onClick={showNewListPopup}>
        <FormattedMessage id="FavoritesPage.createFavoritesList" />
      </div>
    </div>
  )
};

export default AddToExistingFavorites;
