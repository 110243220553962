import {transitions} from '../transactions/transactionProcessBooking';

export const completedTransitions = [
  transitions.COMPLETE,
  transitions.COMPLETE_NO_DEPOSIT,
  transitions.OPERATOR_COMPLETE,
  transitions.OPERATOR_COMPLETE_NO_DEPOSIT,
  transitions.REVIEW_1_BY_CUSTOMER,
  transitions.REVIEW_1_BY_PROVIDER,
  transitions.REVIEW_2_BY_CUSTOMER,
  transitions.REVIEW_2_BY_PROVIDER,
  transitions.EXPIRE_REVIEW_PERIOD,
  transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
  transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
  transitions.CANCEL,
  transitions.CANCEL_NO_DEPOSIT,
  transitions.EXPIRE,
  transitions.DECLINE,
  transitions.CUSTOMER_CANCEL,
  transitions.CUSTOMER_CANCEL_NO_DEPOSIT,
  transitions.CUSTOMER_CANCEL_FULL_REFUND,
  transitions.CUSTOMER_CANCEL_FULL_REFUND_NO_DEPOSIT,
  transitions.PROVIDER_CANCEL,
  transitions.PROVIDER_CANCEL_NO_DEPOSIT,
  transitions.BOOKING_PERIOD_END,
  transitions.BOOKING_PERIOD_END_AFTER_OPERATOR_COMPLETE,
  transitions.APPROVED,
  transitions.NOT_APPROVED,
  transitions.AUTO_APPROVED,
];
