import React, { useState, useEffect, useRef } from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import AdvancedSearchForm from './AdvancedSearchForm';
import classNames from 'classnames';
import { parse } from '../../util/urlHelpers';
import css from './AdvancedSearch.module.css';
import {isEqual, isEmpty} from "lodash";
import { statesOptions } from '../../containers/SearchPage/statesConfig';
import {types as sdkTypes} from "../../util/sdkLoader";
const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;


const AdvancedSearch = props => {
  const { intl, topbarLayout, currentPage, location, listings, onUpdateSearchRange } = props;
  const [datesFilter, setDatesFilter] = useState({});
  const [updatedValues, setUpdatedValues] = useState({});

  const updatesDatesFilter = dates => {
    setDatesFilter(dates);
  };

  const onSubmit = () => {
    return;
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevListings = usePrevious(listings);
  useEffect(() => {

    if(!isEqual(prevListings, listings) && listings.length === 0 && prevListings !== undefined && !isEmpty(updatedValues)) {
      const stateBounds = statesOptions.find(state => {
        const updatedState = updatedValues.location.selectedPlace.address.toLowerCase() || updatedValues.location.state.toLowerCase();
        if(updatedState.includes(state.key) || updatedState.includes(state.keyUA)){
          return state
        }
      });

      const address = updatedValues?.location?.state.toLowerCase();

      const values = {
        location: {
          selectedPlace: {
            address: updatedValues?.location?.state,
            bounds: stateBounds?.bounds,
            origin: stateBounds?.origin,
          }
        }
      }

      const hasState = statesOptions.some(state => address.includes(state.key) || address.includes(state.keyUA))

      window.setTimeout(() => {
        if(hasState) {
          onUpdateSearchRange(true);
          getValues(values);
        }

      }, 1000);
    }

  }, [listings])


  const selectedFilters = parse(location.search);

  const getValues = values => {
    const { history, routeConfiguration } = props;

    const searchParams = {
      address: !!values?.location?.selectedPlace?.address
        ? values?.location?.selectedPlace?.address
        : null,
      bounds: !!values?.location?.selectedPlace?.bounds
        ? values?.location?.selectedPlace?.bounds
        : null,
      origin: !!values?.location?.selectedPlace?.origin
        ? values?.location?.selectedPlace?.origin
        : null,

      dates: !!datesFilter?.dates ? datesFilter.dates : null,
      pub_additionalServicesPresent: selectedFilters?.pub_additionalServicesPresent || 'basicPrice',
      pub_itemType: selectedFilters?.pub_itemType || null
    };

    setUpdatedValues(values)

    !!history && history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };


  const onClearLocation = () => {
    const { history, routeConfiguration } = props;

    const searchParams = {
      address: null,
      bounds: null,
      origin: null,

      dates: !!datesFilter?.dates ? datesFilter.dates : null,
      pub_additionalServicesPresent: selectedFilters?.pub_additionalServicesPresent || 'basicPrice',
      pub_itemType: selectedFilters?.pub_itemType || null
    };

    if(currentPage === "SearchPage") {
      !!history && history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
    }
  };

  const { address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const initialSearchFormValues = {
    location: {
      search: address,
      selectedPlace: { address, origin, bounds },
    }
  };

  const datesFilterInitialValues = {dates: parse(location.search)?.dates}

  const onClearDates = () => {
    setDatesFilter({});

    const { history, routeConfiguration } = props;
    const { address = null, origin = null, bounds = null } = initialSearchFormValues?.location?.selectedPlace;

    const searchParams = {
      address: address || null,
      bounds: bounds || null,
      origin: origin || null,
      dates: null,
      pub_additionalServicesPresent: selectedFilters?.pub_additionalServicesPresent || 'basicPrice',
      pub_itemType: selectedFilters?.pub_itemType || null
    };

    if(currentPage === "SearchPage") {
      !!history && history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
    }
  };


  return (
    <div className={classNames(css.root, { [css.rootTopbar]: !!topbarLayout })}>
      <AdvancedSearchForm
        topbarLayout={topbarLayout}
        onSubmit={onSubmit}
        getValues={getValues}
        intl={intl}

        updatesDatesFilter={updatesDatesFilter}
        datesFilter={!!datesFilter.dates ? datesFilter : datesFilterInitialValues}

        initialValues={initialSearchFormValues}
        onClearLocation={onClearLocation}
        onClearDates={onClearDates}
        currentPage={currentPage}
      />
    </div>
  );
};

AdvancedSearch.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AdvancedSearch);
