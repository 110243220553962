const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const extractCityFromGeocodingResults = (results) => {
  for (let i = 0; i < results.length; i++) {
    const addressComponents = results[i].address_components;
    for (let j = 0; j < addressComponents.length; j++) {
      const component = addressComponents[j];
      if (component.types.includes('locality')) {
        return component.long_name;
      }
    }
  }
  return null; // City not found
}

export const getListingCityName = async (latitude, longitude) => {
  const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

  return fetch(geocodingUrl)
    .then(response => response.json())
    .then(data => {
      if (data.status === 'OK' && data.results.length > 0) {
        const city = extractCityFromGeocodingResults(data.results);
        return city;
      } else {
        return 'Reverse geocoding failed.';
      }
    })
    .catch(error => {
      return error;
    });
}
