import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactCrop from 'react-image-crop';
import { func, bool, number } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import {Button, SecondaryButton, Modal} from '..';
import { manageDisableScrolling } from '../../ducks/ui.duck';

import css from './ImageCrop.module.css';
// import 'react-image-crop/dist/ReactCrop.css'


const CROP_START_SIZE = 100;

const defaultState = {
  file: null,
  crop: {
    unit: 'px',
    width: CROP_START_SIZE,
    height: CROP_START_SIZE,
    x: 5,
    y: 5,
  },
  croppedImage: null,
  imageRef: null
}

const setDefaultState = (props) => {
  return {
    ...defaultState,
    crop: {
      unit: 'px',
      width: props?.minWidth || CROP_START_SIZE,
      height: props?.minHeight || CROP_START_SIZE,
      x: 5,
      y: 5,
    }
  }
}

class ImageCrop extends Component {
  constructor(props) {
    super(props)

    this.state = setDefaultState(props);
    this.inputRef = React.createRef();
    this.imgRef = React.createRef();
  }

  componentDidUpdate(prevProps){
    const {isOpen} = this.props;

    if (isOpen !== prevProps.isOpen && !!isOpen){
      this.inputRef.current.click();
    }
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const {type, name} = e.target.files[0];
      reader.addEventListener('load', () => {
        return this.setState({ file: {src: reader.result, type, name} })
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = async (image, crop, type, name) => {

    await this.setState({imageRef: image})
    this.makeClientCrop(crop, type, name);
  };

  onCropComplete = (crop, type, name) => {
    this.makeClientCrop(crop, type, name);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop: percentCrop });
  };

  makeClientCrop(crop, type, name) {
    const image = this.state.imageRef;
    if (this.state.imageRef && crop.width && crop.height) {

      this.getCroppedImg(
        this.state.imageRef,
        crop = crop.width === CROP_START_SIZE ? {
          unit: 'px',
          width: image?.width,
          height: image?.height,
          x: 0,
          y: 0,
        } : crop,
        name,
        type
      ).then(croppedImage => this.setState({ croppedImage }));
    }
  }

  getCroppedImg(image, crop, fileName, type) {
    if (typeof document !== undefined) {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = Math.ceil(crop.width*scaleX);
      canvas.height = Math.ceil(crop.height*scaleY);
      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width*scaleX,
        crop.height*scaleY,
      );
      // As Base64 string
      // const base64Image = canvas.toDataURL('image/jpeg');
      // As a blob

      return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
          blob.name = fileName;
          resolve(blob);
        }, type, 0.8);
      });
    }

    return Promise.resolve(null);
  }

  handleCreateFile(e){
    if (e) {
      e.preventDefault();
    }
    const {submitFile} = this.props;
    const {file, croppedImage} = this.state;

    if (!file) {
      return;
    }

    const {type, name} = file;

    const f = new File([croppedImage], name, {type})

    submitFile(f);
    this.handleCancel();
  }

  handleCancel(e){
    if (e){
      e.preventDefault();
    }

    this.setState(setDefaultState(this.props));
    this.inputRef.current.value = null;
    this.props.onClose();
  }

  render() {
    const { crop, file } = this.state;
    const { src, type, name } = file || {};
    const { onManageDisableScrolling, minWidth, minHeight } = this.props;

    return (
      <>
        <input
          ref={this.inputRef}
          type="file"
          accept="image/*"
          onChange={this.onSelectFile}
          hidden
          style={{display: 'none'}}
        />

        <Modal
          id="ImageCrop"
          isOpen={!!file}
          onClose={() => this.handleCancel()}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          {src && (
            <div className={css.cropContainer}>
              <ReactCrop
                crop={crop}
                onChange={this.onCropChange}
                onComplete={crop => this.onCropComplete(crop, type, name)}
                aspect={1}
                minWidth={minWidth}
                minHeight={minHeight}
              >
                <img
                  ref={this.imgRef}
                  alt="Crop me"
                  src={src}
                  onLoad={e => this.onImageLoaded(this.imgRef.current, crop, type, name)}
                />
              </ReactCrop>
            </div>
          )}

          <div className={css.buttons}>
            <Button
              type="button"
              onClick={(e) => this.handleCreateFile(e)}
            >
              <FormattedMessage id="ImageCrop.save"/>
            </Button>

            <SecondaryButton
              type="button"
              onClick={(e) => this.handleCancel()}
            >
              <FormattedMessage id="ImageCrop.cancel"/>
            </SecondaryButton>
          </div>


          {/* {croppedImageUrl && (
            <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
          )} */}
        </Modal>
      </>
    );
  }
}

ImageCrop.defaultProps = {
  aspect: 16 / 10
}

ImageCrop.propTypes = {
  isOpen: bool.isRequired,
  submitFile: func.isRequired,
  onClose: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  aspect: number
}

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default connect( null, mapDispatchToProps )(ImageCrop);
